import { Clear, Done } from '@mui/icons-material';
import { Box, Button, Collapse, IconButton, Stack, TextField, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { useState } from 'react';

import { usePostComment } from '@hooks/useApiComment';

type Props = {
  photoId: string;
  onClose: () => void;
  initialText?: string;
};

export const CommentForm = ({ photoId, onClose, initialText = '' }: Props) => {
  const [comment, setComment] = useState(initialText);
  const [error, setError] = useState('');

  const theme = useTheme();
  const queryClient = useQueryClient();
  const postCommentMutation = usePostComment();

  const handleSubmit = async () => {
    if (comment.trim() === '') {
      setError(t('common.form.nonEmpty'));

      return;
    }

    try {
      await postCommentMutation.mutateAsync(
        { text: comment, photoId },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ['photoClient.getPhoto', photoId],
            });
          },
        },
      );
      setComment('');
      setError('');
      onClose();
    } catch (error) {
      setError(t('common.form.failed'));
    }
  };

  return (
    <Stack component="form" direction="row" onSubmit={handleSubmit} spacing={1}>
      <TextField
        InputLabelProps={{
          sx: {
            top: theme.spacing(0.25),
          },
        }}
        error={!!error}
        fullWidth
        helperText={error ? error : ''}
        label={t('components.photoInfoCard.info.comment')}
        multiline
        onChange={(e) => setComment(e.target.value)}
        value={comment}
        variant="outlined"
      />
      <IconButton onClick={() => onClose()} sx={{ borderRadius: theme.spacing(1) }}>
        <Clear />
      </IconButton>
      <IconButton onClick={handleSubmit} sx={{ borderRadius: theme.spacing(1) }}>
        <Done />
      </IconButton>
    </Stack>
  );
};

type PostCommentProps = { photoId: string };

export const PostComment = ({ photoId }: PostCommentProps) => {
  const [isFormVisible, setFormVisible] = useState(false);

  return (
    <Box component="div">
      <Collapse in={!isFormVisible} timeout="auto">
        <Button onClick={() => setFormVisible(true)} variant="text">
          + {t('components.photoInfoCard.comment')}
        </Button>
      </Collapse>
      <Collapse in={isFormVisible} timeout="auto" unmountOnExit>
        <CommentForm onClose={() => setFormVisible(false)} photoId={photoId} />
      </Collapse>
    </Box>
  );
};
