import { photoApi } from '@deepup/apis';
import { Clear } from '@mui/icons-material';
import { FormControl, IconButton, Stack, TextField } from '@mui/material';
import { t } from 'i18next';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

import { DateRangePicker } from '@components/FilterBox/fields/DateRangePicker';
import { SelectBoxMultiReversed } from '@components/FilterBox/fields/SelectBoxMulti';
import { useListPhotos } from '@hooks/useApiPhoto';
import type { ProjectEntity } from '@hooks/useApiProjects';
import { distinct } from '@utils/core';

import { DownloadButton } from './DownloadButton/DownloadButton';
import { PhotoSidebar } from './PhotoSidebar';
import { ProjectAutocomplete } from './ProjectAutocomplete';
import { PhotoTable } from './Table/Table';
import { isInTextSearch, isDateInRange, isInScanDevices, isInCategories } from './utils';

export type DateRange = {
  to: DateTime | null;
  from: DateTime | null;
};

export const PhotoDownload = () => {
  const [project, setProject] = useState<ProjectEntity | null>(null);
  const [photo, setPhoto] = useState<photoApi.Photo | null>(null);

  const [dateRange, setDateRange] = useState<DateRange>({ to: null, from: null });
  const [scanDevices, setScanDevices] = useState<string[]>([]);
  const [photoCategories, setPhotoCategories] = useState<string[]>([]);
  const [search, setSearch] = useState('');

  const { data: photoData } = useListPhotos(project?.id || null);

  useEffect(() => {
    if (photoData) {
      setScanDevices([]);
      setPhotoCategories([]);
      setSearch('');
      setDateRange({ to: null, from: null });
    }
  }, [photoData]);

  const photos = photoData?.items || [];

  const availableDevices = distinct(photos.map((item) => item.deviceId));
  const availableCategories = distinct(photos.map((item) => item.categoryName));

  const filteredPhotos = photos
    .filter(isInTextSearch(search))
    .filter(isDateInRange(dateRange))
    .filter(isInScanDevices(scanDevices, availableDevices))
    .filter(isInCategories(photoCategories, availableCategories));

  return (
    <>
      <Stack component="div" mt={2} p={2} spacing={4}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <ProjectAutocomplete project={project} setProject={setProject} />
          {project && <DownloadButton photos={filteredPhotos} project={project} />}
        </Stack>
        {!!photos.length && (
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} />
            <SelectBoxMultiReversed
              availableItems={availableDevices}
              items={scanDevices}
              label={t('components.filterBox.scanDeviceSelect.label')}
              setItems={setScanDevices}
            />
            <SelectBoxMultiReversed
              availableItems={availableCategories}
              items={photoCategories}
              label={t('components.filterBox.photoCategorySelect.label')}
              setItems={setPhotoCategories}
            />
            <FormControl fullWidth>
              <TextField
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => setSearch('')}
                      sx={{ display: search ? 'visible' : 'none' }}
                    >
                      <Clear />
                    </IconButton>
                  ),
                }}
                fullWidth
                label={t('pages.map.photos.search.label')}
                onInput={(e) => setSearch((e.target as HTMLInputElement).value)}
                placeholder={t('pages.map.photos.search.label')}
                value={search}
              />
            </FormControl>
          </Stack>
        )}

        <PhotoTable photos={filteredPhotos} setPhoto={setPhoto} />
      </Stack>
      {photo && <PhotoSidebar photo={photo} setPhoto={setPhoto} />}
    </>
  );
};
