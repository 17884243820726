import { photoApi, photoClientApi } from '@deepup/apis';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useState } from 'react';

import { useGrpcRestTransport } from '@hooks/useGrpcRestTransport';
import { fromTimestamp } from '@utils/timeFormatting';

export const usePhotoPackage = (projectId: string) => {
  const [isPollingActive, setPollingActive] = useState(false);
  const transport = useGrpcRestTransport();

  return useQuery({
    queryKey: ['photoClient.listPhotoPackages', projectId],
    queryFn: async () => {
      const photoClient = new photoClientApi.PhotoServiceClient(transport);
      const packages = await photoClient.listPhotoPackages({ projectId }).response;

      if (!packages.items.length) {
        return null;
      }

      const [latestPackage, ...pastPackages] = packages.items.sort((a, b) => {
        if (a.createdAt && b.createdAt) {
          return fromTimestamp(b.createdAt).toMillis() - fromTimestamp(a.createdAt).toMillis();
        }

        return -1;
      });

      if (latestPackage.status === photoApi.PhotoPackageStatus.DONE) {
        const downloadLink = await photoClient.downloadPhotoPackage({ id: latestPackage.id })
          .response;
        const pollingState = false;

        setPollingActive(pollingState);

        return {
          photoPackage: latestPackage,
          downloadLink,
          isPollingActive: pollingState,
        };
      }

      const availablePackage = pastPackages.find(
        (item) => item.status === photoApi.PhotoPackageStatus.DONE,
      );

      const downloadLink = availablePackage
        ? await photoClient.downloadPhotoPackage({ id: availablePackage.id }).response
        : undefined;

      const pollingState = latestPackage.status === photoApi.PhotoPackageStatus.PENDING;

      setPollingActive(pollingState);

      return {
        photoPackage: availablePackage,
        isPollingActive: pollingState,
        downloadLink,
      };
    },
    refetchInterval: isPollingActive ? 2000 : false,
  });
};

export const useDownloadPackage = (packageId: string) => {
  const transport = useGrpcRestTransport();

  return useQuery({
    queryKey: ['photoClient.downloadPhotoPackage', packageId],
    queryFn: async () => {
      const photoClient = new photoClientApi.PhotoServiceClient(transport);

      return photoClient.downloadPhotoPackage({ id: packageId }).response;
    },
  });
};

export const useCreatePackage = () => {
  const transport = useGrpcRestTransport();

  const mutation = useMutation<
    photoApi.PhotoPackage,
    { error: { errors: string[] } },
    photoApi.CreatePhotoPackageRequest
  >({
    mutationFn: ({ projectId, photoIds }) => {
      const photoClient = new photoClientApi.PhotoServiceClient(transport);

      return photoClient.createPhotoPackage({ projectId, photoIds }).response;
    },
  });

  return mutation;
};
