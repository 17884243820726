import { type Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { photoClientApi } from '@deepup/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import { useGrpcRestTransport } from '@hooks/useGrpcRestTransport';
import { FetchError } from '@utils/FetchError';
import { getEnvironment } from '@utils/getEnvironment';

export type PhotoDetails = {
  id: string;
  date: DateTime;
  text?: string;
  address?: string;
  scanDeviceName: string;
  solution: string;
  categoryName?: string;
  longitude: number;
  latitude: number;
};

type PhotoDetailsDTO = Omit<Partial<PhotoDetails>, 'date'> & { date?: string };

const convertToPhotoDetail = <T extends PhotoDetailsDTO>(input: T): PhotoDetails | undefined => {
  if (
    input &&
    typeof input.id === 'string' &&
    typeof input.date === 'string' &&
    typeof input.scanDeviceName === 'string' &&
    typeof input.solution === 'string' &&
    typeof input.longitude === 'number' &&
    typeof input.latitude === 'number'
  ) {
    // make date
    const date = DateTime.fromJSDate(new Date(input.date), { zone: 'utc' })
      .reconfigure({ locale: 'de-DE' })
      .setZone('cet');

    if (!date.isValid) {
      return undefined;
    }

    // Optional properties
    return {
      id: input.id,
      date: date,
      scanDeviceName: input.scanDeviceName,
      solution: input.solution,
      longitude: input.longitude,
      latitude: input.latitude,
      text: typeof input.text === 'string' ? input.text : undefined,
      address: typeof input.address === 'string' ? input.address : undefined,
      categoryName: typeof input.categoryName === 'string' ? input.categoryName : undefined,
    };
  }

  return undefined;
};

const fetchPhoto = async (auth: Auth0ContextInterface, photoId: string): Promise<PhotoDetails> => {
  const { apiConMon } = getEnvironment();

  const token = await auth.getAccessTokenSilently();
  const response = await fetch(`${apiConMon}photos/${photoId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const responseJson = await response.json();
  const photoDetails = convertToPhotoDetail(responseJson);

  if (!response.ok || !photoDetails) {
    throw new FetchError(response, responseJson);
  }

  return photoDetails;
};

export const useApiPhoto = (photoId: string, options = { enabled: true }) => {
  const auth0 = useAuth0();

  return useQuery<PhotoDetails, FetchError | Error>({
    queryKey: ['useApiPhotos', photoId],
    queryFn: () => fetchPhoto(auth0, photoId),
    ...options,
  });
};

export const useListPhotos = (projectId: string | null) => {
  const transport = useGrpcRestTransport();
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['photoClient.listPhotos', projectId],
    queryFn: async () => {
      if (projectId) {
        const photoClient = new photoClientApi.PhotoServiceClient(transport);

        const listPhotos = await photoClient.listPhotos({ projectId }).response;

        listPhotos.items?.forEach((photoItem) => {
          queryClient.setQueryData(['photoClient.getPhoto', photoItem.id], photoItem);
        });

        return listPhotos;
      }
    },
    enabled: !!projectId,
  });
};

export const usePhotoItem = (id: string) => {
  const transport = useGrpcRestTransport();

  return useQuery({
    queryKey: ['photoClient.getPhoto', id],
    queryFn: () => {
      const photoClient = new photoClientApi.PhotoServiceClient(transport);

      return photoClient.getPhoto({ id }).response;
    },
  });
};
