import { photoApi } from '@deepup/apis';

import { fromTimestamp } from '@utils/timeFormatting';

export enum Expiration {
  expired = 0,
  available = 1,
  unknown = 2,
}

export const getLinkExpiration = (downloadLink?: photoApi.DownloadPhotoPackageResponse) => {
  if (!downloadLink || !downloadLink.expiresAt) {
    return Expiration.unknown;
  }

  return fromTimestamp(downloadLink.expiresAt).diffNow().milliseconds < 0
    ? Expiration.expired
    : Expiration.available;
};

export const startDownloading = async (downloadUrl: string, projectName: string) => {
  const link = document.createElement('a');
  const date = new Date().toISOString().slice(0, 16).replace('T', '_');

  // TODO - add url when API is ready, test package downloading and naming
  const blob = new Blob([`mocked zip content fetched from ${downloadUrl}`]);
  const href = window.URL.createObjectURL(blob as Blob);

  link.download = `photos_${projectName.replace(/\s/g, '_')}_${date}.zip`;
  link.href = href;
  link.click();
};
