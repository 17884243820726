import { photoApi } from '@deepup/apis';
import { PhotoFilled } from '@deepup/icons';
import { Box, Button, Tooltip, Typography, type Theme } from '@mui/material';
import { t } from 'i18next';

import { ImageWithSource } from '@components/Image';
import { useImageSource } from '@hooks/useImageSource';

const getGeolocationToolTip = (geoPositionSolution?: photoApi.GeolocationSolution): string => {
  switch (geoPositionSolution) {
    case photoApi.GeolocationSolution.CORELOCATION:
      return 'meter';
    case photoApi.GeolocationSolution.FLOAT:
      return 'decimeter';
    case photoApi.GeolocationSolution.FIXED:
      return 'centimeter';
    default:
      return 'Unknown';
  }
};

export const GeoPositionSolutionCell = ({
  geoPositionSolution,
}: {
  geoPositionSolution: photoApi.GeolocationSolution;
}) => {
  return (
    <Tooltip
      arrow
      placement="right"
      title={<div>{getGeolocationToolTip(geoPositionSolution)}</div>}
    >
      <Box
        component="div"
        sx={(theme: Theme) => ({
          backgroundColor:
            geoPositionSolution === photoApi.GeolocationSolution.CORELOCATION
              ? theme.deepupColors.red[500]
              : theme.deepupColors.green[600],
          borderRadius: theme.shape.borderRadius,
          height: theme.spacing(3),
          width: theme.spacing(3),
          display: 'inline-flex',
        })}
      ></Box>
    </Tooltip>
  );
};

type ImageCellProps = photoApi.Photo;

export const ImageCell = (props: ImageCellProps) => {
  const { id } = props;
  const url = useImageSource({ id });

  const isError = url instanceof Error;

  return (
    <Tooltip
      arrow
      placement="right"
      title={
        <ImageWithSource
          alt={`${t('pages.map.photo.imageAltCategory')}:${props.categoryName}`}
          height={500}
          imageSource={url}
          sx={{
            width: '100%',
            height: '100%',
            display: 'block',
          }}
          sxLoading={{
            width: '100%',
            height: '500px',
          }}
        />
      }
    >
      <Button
        onClick={typeof url === 'string' ? () => window.open(url, '_blank') : undefined}
        sx={{
          height: 60,
        }}
        variant="text"
      >
        {!isError ? (
          <ImageWithSource
            alt={`${t('pages.map.photo.imageAltCategory')}:${props.categoryName}`}
            height={60}
            imageSource={url}
            sx={{
              width: '100%',
              height: '100%',
              display: 'block',
            }}
            sxLoading={{
              width: '100%',
              height: '60px',
            }}
          />
        ) : (
          <PhotoFilled />
        )}
      </Button>
    </Tooltip>
  );
};

export const MultilineText = ({ text }: { text?: string | null }) => {
  if (!text) return null;

  return (
    <Typography variant="body2" whiteSpace="normal">
      {text}
    </Typography>
  );
};
