import { useMutation } from '@tanstack/react-query';

type CommentPost = {
  text: string;
  photoId: string;
};

export const usePostComment = () => {
  const mutation = useMutation<{ data: CommentPost }, { error: { errors: string[] } }, CommentPost>(
    {
      mutationFn: async ({ text, photoId }) => {
        // TODO - replace with commentClientApi when it's ready
        const response = await fetch(`/api/comment/v1/post`, {
          body: JSON.stringify({ text, photoId }),
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        return response.json();
      },
    },
  );

  return mutation;
};
